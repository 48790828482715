<template>
    <div>
        <section class="header-bg" style="">
        </section>
        <section class="container-1200" style="margin-top: 40px;">
            <div style="height: 20px;">
                <p class="title-hr"><strong style="font-size: 28px;font-family: 'Bebas Neue', cursive;">BROWSE Author</strong></p>
            </div>
            <div style="margin-top: 80px;text-align: center;">
                <p class="layui-font-green layui-font-20">No Data</p>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "author",
		data(){
			return{
			}
		},
		methods:{
			
		}
    }
</script>

<style></style>